<template>
  <div>
    <section class="section-top progress">
      <h1 class="section-top-title">隱私權保護政策</h1>
    </section>
    <section class="section-terms section">
      <div class="section-content">
        <article>
          <p>
            竭誠歡迎您使用旭電馳科研股份有限公司旗下《U-POWER》超高速直流充電服務，為您的愛車充飽電力。為提供最好的服務品質，在服務過程之中，我們將會取得您的個人資料，這些資料將按照下列的隱私權保護政策進行使用及保存。為保障您的權益，在使用《U-POWER》各項服務與資訊前，請您詳細閱讀以下有關隱私權保護政策暨免責聲明的相關內容，若您點選同意並繼續使用，表示您已閱讀、瞭解並同意接受本政策暨免責聲明的所有的內容。
          </p>
          <h3 id="一-隱私權保護政策之適用範圍">
            (一) 隱私權保護政策之適用範圍
          </h3>
          <p>
            本政策僅適用於旭電馳科研股份有限公司旗下所有服務。雖您可經由本公司的網站、App
            所提供的連結，點選進入其他網站或
            App，但本政策並不適用於您從本網站所連結之其他網站或 App。
          </p>
          <p>
            您得自由選擇是否提供本公司的網站、APP指定之個人資料，若您提供之個人資料不足或有誤時，將可能無法成為本公司的網站、APP之會員。為提供予您更多元之服務，對於您所提供之個人資料，將會依個人資料保護法之規定來蒐集、處理及利用個人資料，我們依個人資料保護法第8條規定告知下列事項。
          </p>
          <h3 id="二-個人資料的蒐集範圍與目的">
            (二) 個人資料的蒐集範圍與目的
          </h3>
          <p>
            蒐集目的：<br />為了提供您最佳的服務品質、客制化內容及服務、提供改進方案、進行市場研究、趨勢分析，視服務需求，將請您提供相關個人資料，並將記錄您使用服務時相關資訊。
          </p>
          <p>蒐集時機：</p>
          <ul>
            <li>在您加入會員、註冊帳號時</li>
            <li>在您使用聯絡我們功能時</li>
            <li>在您使用 U-POWER 超高速充電服務時</li>
            <li>在您使用 U-POWER 合作夥伴所提供服務時</li>
          </ul>
          <p>蒐集範圍：</p>
          <p>
            U-POWER
            超高速充電服務所蒐集的個人資料包含您的姓名、暱稱、電子郵件、地址、電話、汽車相關資料、支付工具、發票資訊等，同時亦會記錄您使用
            U-POWER 超高速充電服務各種相關資訊。經由本公司部份網頁及 App
            所使用之
            cookie，本公司伺服器會自動接收並記錄您電腦和連線設備的設備資訊、系統資訊、IP
            位置、瀏覽記錄。
          </p>
          <p>
            當您使用 U-POWER 合作夥伴所提供服務時，U-POWER
            亦蒐集合作夥伴所提供的資訊。隨服務功能擴大及新增，將視需求請您提供更新資訊。
          </p>
          <h3 id="三-個人資料的保護與利用">(三) 個人資料的保護與利用</h3>
          <p>
            自您加入成為本APP之會員，本公司將留存您提供的個人資料，直至依服務條款規範刪除帳號之手續辦理完畢或本公司停止提供服務為止。
          </p>
          <p>您的個人資料使用地區僅為我們營運之地區內使用。</p>
          <p>
            U-POWER
            將盡力保障您的個人資料。旭電馳科研股份有限公司僅在下列情形，才會將您的個人資料交付特定第三方：
          </p>
          <ul>
            <li>經由您同意。</li>
            <li>配合法令、依司法機關或其他相關政府之命令或要求。</li>
            <li>經您授權使用您個人資料以提供您所需的特定服務時。</li>
            <li>
              您如因車廠或企業客戶(以下兩者合併簡稱為「企業客戶」)與U-POWER合作因素而取得充電優惠身分或U-POINT，依照企業客戶與U-POWER之合約約定，您使用充電服務前需成為會員，U-POWER可透過企業客戶取得使用者之必要會員資料以提供服務，且您亦同意企業客戶得於您享有優惠身分期間或取得之U-POINT，可調閱充電紀錄進行財務稽核之用
            </li>
            <li>為免除您生命、身體、自由或財產上之危險。</li>
            <li>
              當您在本公司的網站或APP的行為，違反本公司的網站或APP的服務條款或可能損害或妨礙本公司的網站或APP權益或導致任何人遭受損害時，經本公司的網站或APP研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
            </li>
          </ul>
          <p>
            基於市場推展及電動車推廣，U-POWER
            將以去識別化方式統整分析使用者使用服務時的各項資訊，並使用統整分析之研究成果。
          </p>
          <h3 id="四-個人資料的管理">(四) 個人資料的管理</h3>
          <p>
            U-POWER
            將根據您提供的個人資料製成電子檔案，並進行資料之記錄、輸入、儲存、編輯、更正、複製、檢索、刪除、輸出、連結或內部傳送。另外，U-POWER
            網站及 App 之 Cookie 所蒐集的資料僅有 IP
            位置、瀏覽的網頁記錄，不包括其他足以識別您的資料，您可以決定是否使用
            Cookie。
          </p>
          <p>您就您的個人資料依法得向我們請求行使下開權利：</p>
          <ul>
            <li>查詢或請求閱覽。</li>
            <li>請求製給複製本。</li>
            <li>請求補充或更正。</li>
            <li>請求停止蒐集、處理或利用。</li>
            <li>請求刪除。</li>
          </ul>
          <h3 id="五-刪除帳號">(五) 刪除帳號</h3>
          <p>
            您可隨時要求刪除自己的帳戶。收到刪除帳戶要求後，U-POWER
            會刪除使用者的帳戶和資料，但會保留相關法律或規範要求留存的資料，以便處理與使用者帳戶相關的問題
            (例如未使用的 U-POINT
            或尚未解決的索賠或爭議)。除基於上述原因需要保留資料，因牽涉銀行與發票作業時間需要，我們會在收到刪除要求的
            60 天內刪除資料，期間此帳號註冊的手機及 Email 將無法使用。
          </p>
          <p>如果您要刪除帳號，請注意：</p>
          <ul>
            <li>帳號一經刪除後，便無法恢復。</li>
            <li>
              帳號刪除後，您的個人檔案將永久刪除，並且資料刪除後將無法復原，充電點數（U-POINT）也無法轉讓予他人。
            </li>
            <li>
              部分資料的副本（例如：充電記錄）會保留在我們的資料庫，但不會再與任何可識別個人身分的資料有關聯。
            </li>
            <li>
              若您的充電記錄中有未付款的充電服務，您將無法刪除帳號，在款項結清前您的帳號也無法使用充電服務。
            </li>
          </ul>
          <p>如何刪除我的帳號？</p>
          <ul>
            <li>點擊 U-POWER App 左下角會員頭像。</li>
            <li>選擇右上角修改會員資料設定。</li>
            <li>選擇刪除帳號，然後點擊仍要刪除帳號。</li>
            <li>
              確認刪除會員帳號及資料
              <ul class="child-ul">
                <li>
                  若您的帳號內仍有未使用的
                  U-POINT，需下載「會員刪除帳號資料申請書」，填寫完將申請書正本以掛號寄回旭電馳科研股份有限公司；或以電子郵件方式
                  Email 至
                  <a
                    href="mailto:&#x63;&#x73;&#x40;&#x75;&#x2d;&#112;&#x6f;&#x77;&#x65;&#114;&#46;&#x63;&#111;&#x6d;&#x2e;&#116;&#x77;"
                    >&#x63;&#x73;&#x40;&#x75;&#x2d;&#112;&#x6f;&#x77;&#x65;&#114;&#46;&#x63;&#111;&#x6d;&#x2e;&#116;&#x77;</a
                  >，客服人員將協助您完成帳號刪除的後續步驟。您有<strong>七個工作天</strong>反悔期，期間您將無法使用
                  App
                  會員功能及充電卡，若未提出書面申請，視同未完成刪除帳號，U-POWER
                  將恢復您的會員帳號。
                </li>
                <li>
                  若您的帳號沒有 U-POINT 等有價點數或儲值金，在 App
                  送出刪除申請後完成刪除帳號，該帳號使用的手機號碼及 Email 需 2
                  個月後才能重新於 U-POWER 使用。
                </li>
              </ul>
            </li>
          </ul>
          <h3 id="六-cookie">(六) Cookie</h3>
          <p>
            Cookie
            是在造訪網站時儲存在您電腦中的小型文字檔案。當之後再次造訪時，網站便會讀取此文字檔案儲存資訊。為使網站正常運作，部份
            Cookies 是必要的。
          </p>
          <p>
            為了提供您最佳的服務，U-POWER 網站及 App
            會在您的裝置中放置並取用本公司的
            Cookie，為了統計與使用分析的用途、提供您客製化的廣告服務及其他功能的體驗，U-POWER
            網站及App 使用以下的第三方 Cookies，包含Google Tag Manager、Google
            Analytics cookie 、 Google DoubleClick cookie 、 Alexa 以及
            ComScore。若您不願接受 Cookie
            的寫入，您可在您使用的瀏覽器功能項中設定隱私權，即可取消或限制
            Cookie 的寫入，但可能會導致網站及 App 某些功能無法正常執行。
          </p>
          <h3 id="七-免責聲明">(七) 免責聲明</h3>
          <p>個人資料正確性：</p>
          <p>
            《U-POWER》超高速充電服務將以簡訊方式傳送充電進度，並以 Email
            提供電子發票收據，請詳加確認您所填寫的資料，若因填寫錯誤或遺失恕不再補發。
          </p>
          <p>連結至 U-POWER 網站及 App：</p>
          <p>
            不得未經旭電馳科研股份有限公司同意，連結 U-POWER 任何網站、網頁及 App
            做為網路行銷之用，旭電馳科研股份有限公司保留一切法律追訴權。
          </p>
          <p>U-POWER 網站之相關連結：</p>
          <p>
            僅為使用者之便利與政令配合措施；旭電馳科研股份有限公司網站提供之相關網站連結，對於使用者涉及該網站內容之使用行為，旭電馳科研股份有限公司不負責任。
          </p>
          <p>第三方網頁及服務：</p>
          <p>
            旭電馳科研股份有限公司可能會連接至其他機構所提供的網頁及服務，旭電馳科研股份有限公司不會對這些網頁內容及服務作出任何保證或承擔任何責任。使用者如瀏覽這些網頁及使用這些服務，需自行承擔後果。是否使用旭電馳科研股份有限公司之服務下載或取得任何資料應由用戶自行考慮且自負風險，因前開任何資料之下載而導致用戶
            電腦系統之任何損壞或資料流失，本網站不承擔任何責任。
          </p>
          <h3 id="八-本政策的變更">(八) 本政策的變更</h3>
          <p>
            我們將不定期修改本政策，建議您應時常瀏覽本政策。如您於本政策修改後仍繼續使用本服務，我們將視為您同意我們修改後之本政策；當本政策有重大變更時，我們將於本服務網頁及
            App
            公告或以寄送電子通知信的方式告知。您可查閱本政策頁面最下方以確認本政策之最後更新時間。
          </p>
        </article>
        <p class="last-update">最後更新日期：2024 年 01 月 03 日</p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Privacy",
};
</script>
